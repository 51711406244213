import React from 'react';
import styled from "styled-components";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import {graphql} from "gatsby";
import {Link} from "gatsby";
import SEO from "../components/SEO";


const PricesPageStyles = styled.div`
  padding: 3vw;
  letter-spacing: 1px;

  .prices-header {
    font-size: 2.4rem;
    text-align: center;
    margin-bottom: 4rem;
  }

  .prices-selection {
    margin-bottom: 4rem;
  }

  .prices-selection-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2%;
  }

  .prices-selection-box {
    padding: 2rem;
    border: 1px solid #cccccc;
    background: #fafafa;
    flex: 0 0 49%;
    li {
      color: #7c7c7c;
      margin-bottom: .8rem;
    }
  }

  .prices-selection-notes {
    padding: 2rem;
    border: 1px solid #cccccc;
    background: #fafafa;
    p {
      color: #7c7c7c;
    }
  }

  .prices-examples {
    text-align: center;
    margin-bottom: 5rem;
    h2 { margin-bottom: 4rem; }
    img { max-width: initial; }
    .image-slider-box {
      width: 70vw;
      height: auto;
      margin: 0 auto 4rem auto;
    }
  }

  .prices-final-product {
    text-align: center;
    margin-bottom: 5rem;
    max-width: 1000px;
    margin: 0 auto;
    padding: 2rem;
    border: 1px solid #cccccc;
    background: #fafafa;
    margin-bottom: 4rem;
  }

  .product-feature {
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }

  .prices-contact {
    letter-spacing: 1px;
    cursor: pointer;
    color: #000000;
    background: transparent;
    transition: background-color .2s ease;
    border: 1px solid #000000;
    font-size: 1.2rem;
    display: inline;
    width: auto;
    border-radius: 0;
    padding: 1rem 1.5rem;
    text-decoration: none;
  }

  .prices-contact:hover {
    background-color: #000000;
    color: #ffffff;
  }

  .prices-schedule {
    text-align: center;
  }

  @media screen and (max-width: 800px) {
    .prices-selection-container {
      flex-direction: column;
    }

    .prices-selection-box {
      margin-bottom: 3%;
    }

    .prices-examples {
      .image-slider-box {
        width: 100%;
      }
    }
  }
`;

export default function PricesPage({ data }) {
  return (
    <PricesPageStyles>
      <h1 className="prices-header">Pricing</h1>
      <div className="prices-selection">
        <div className="prices-selection-container">
          <div className="prices-selection-box">
            <h3 className="mb-5">Per Hour</h3>
            <ul>
              <li>- First hour - 400 ₪</li>
              <li>- Each additional hour - 200 ₪</li>
              <li>- One hour is approximately 25 images in basic color correction and perspective fix</li>
            </ul>
          </div>
          <div className="prices-selection-box">
            <h3 className="mb-5">Per Image</h3>
            <ul>
              <li>- Shooting fee - 500 ₪</li>
              <li>- 20 ₪ per image</li>
              <li>- All images in basic color correction, perspective fix and retouche</li>
            </ul>
          </div>  
        </div>
        <div className="prices-selection-notes">
          <h3 className="mb-5 text-center">Additional Information</h3>
          <p>Departure to the location with equipment: up to 2 hours on the road - 200 ₪. Each additional hour on the road - 100 ₪.</p>
          <p>Final price and amount of images depend on the details of the project.</p>
          <p className="mb-0">Additional retouche - forgotten objects in the frame, dust and garbage removal and other fixes - 50 ₪ per image</p>
        </div>
        
      </div>

      <div className="prices-examples">
        <h2>Examples of post-production</h2>
        <div className="before-after-content">
          {
            data.images.nodes.map(item => {
              return (
                <div className="image-slider-box" >
                  <ReactCompareSlider
                    itemOne={<ReactCompareSliderImage src={item.image_before.asset.url} alt="Image one" />}
                    itemTwo={<ReactCompareSliderImage src={item.image_after.asset.url} alt="Image two" />}
                  />
                </div>
              )
            })
          }
        </div>
      </div>

      <div className="prices-final-product">
        <h2 className="mb-5">What you get</h2>
        <ul className="prices-final-product-list">
          <li className="product-feature">High quality TIFF format images for print.</li>
          <li className="product-feature">Compressed JPG format image for web and social networks.</li>
          <li className="product-feature">Photographs of all main angles - wide shots, niches and details shots.</li>
          <li className="product-feature">Logo, watermark, signature or any additional information on the photograph.</li>
        </ul>
      </div>
      <div className="prices-schedule">
        <Link to="/contact" className="prices-contact">Contact me</Link>
      </div>
    </PricesPageStyles>
  )
} 

export const works = graphql`
    query beforeAfterImagesQuery {
        images: allSanityBeforeafter {
          nodes {
            image_after {
              asset {
                url
              }
            }
            image_before {
              asset {
                url
              }
            }
          }
        }
    }
`;